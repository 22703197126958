import React, { useEffect, useState } from "react";
import "./TransactionSuccessDialog.css";
import success_icon from "../../assets/Featured icon.png";
import group1 from "../../assets/Group1.png";
import { useNavigate } from "react-router-dom";

interface TransactionSuccessDialogProps {
  transactionData:any,
  isFavourite: boolean;
  addFavourite: () => void;
  onClose: () => void;
}

const TransactionSuccessDialog: React.FC<TransactionSuccessDialogProps> = ({
  transactionData,
  isFavourite,
  addFavourite,
  onClose,
}) => {
  const navigate = useNavigate();
  const [isOpenView, setIsOpenView] = useState(false);

  useEffect(() => {
    // Trigger the transition effect
    setTimeout(() => setIsOpenView(true), 0);
  }, []);

  const handleClose = () => {
    onClose();
    setIsOpenView(false);
    setTimeout(onClose, 500); // Match the transition duration
    navigate(`/transaction-history`,{state:{email:localStorage.getItem("userEmail")}})
  };
  const formatDateTime = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // Use 12-hour format with AM/PM
    };
    const formattedDateTime = new Date(dateString).toLocaleString(undefined, options);
    return formattedDateTime;
  };
  

  return (
    <>
      <div className={`confirmation-dialog-app ${isOpenView ? "show" : ""}`}>
        <div className="d-flex justify-content-end align-items-center">
          <img className="pe-2 close-icon" src={group1} onClick={handleClose} />
        </div>
        <div className="row justify-content-center">
          <img className="pe-2 feature-icon-update" src={success_icon} />
        </div>
        <div className="transaction-value">{transactionData.from_currency_code} {transactionData?.amount}</div>
        <div className="congratz-text vw-100">Congratulations 🎉</div>
        <div className="d-flex justify-content-center topic-app3">
          Your fund transfer successfully completed..!
        </div>
        <div className="transaction-time">{formatDateTime(new Date())}</div>
        {!isFavourite ? (
          <div className="add-favourite" onClick={addFavourite}>
            Add to favourite
          </div>
        ) : (
          ""
        )}
      </div>

      {isOpenView && <div className="confirmation-overlay"></div>}
    </>
  );
};

export default TransactionSuccessDialog;
