import React, { useEffect, useRef, useState } from "react";
import "./TransactionHistory.css";
import { aws_s3 } from "../../../environment/environment";
import {
  getRemitTransactions,
  getTransactions,
} from "../../../services/transaction";
import Pagination from "../../../utilities/Pagination/Pagination";
import { useAsyncValue, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../../utilities/navbar/NavBar";
import arrow_line_right from "../../../assets/arrow-right-line.png";
import arrow_line_right2 from "../../../assets/arrow-right-line2.png";
import whatsapp_icon from "../../../assets/whatsapp-icon.png";
import copy_icon from "../../../assets/file-copy-line.png";
import add_icon from "../../../assets/add-circle-fill (2).png";
import filter_icon from "../../../assets/filter-icon.png";
import visa_icon from "../../../assets/image 20.png";
import master_card_icon from "../../../assets/mastercard_logo.png";
import secure_by from "../../../assets/image 18.png";
import invite_arrow from "../../../assets/invite-arrow.png";
import invite_img from "../../../assets/image 27.png";
import toast from "react-hot-toast";
import ToggleButton from "../../../utilities/toggle-btn/ToggleButton";
import Button from "../../../utilities/button/Button";
import money_icon from "../../../assets/image 19.png"
import LeftOffCanvas from "../../../utilities/left-offcanvas/LeftOffCanvas";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as Loader } from "../../../assets/icons/loader.svg";
import {
  cardTokenize,
  deleteCardTokenize,
  editCardTokenize,
  getTokenizeCardList,
} from "../../../services/card_tokenization";
import CardTokenizationListener from "../../../configs/firebaseConfigs";
import { TransactionReceiptPdf } from "../../../utilities/TransactionReceiptPdf/TransactionReceiptPdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { useContextProvider } from "../../../services/context_provider";
import RetryConfirmationDialog from "../../../utilities/retry-confirmation-dialog/TransactionFailedDialog";
import TransactionFailedDialog from "../../../utilities/retry-confirmation-dialog/TransactionFailedDialog";
import RemitTransactionView from "../../../utilities/remit-transaction-viewer/RemitTransactionView";
import { encryptMessage } from "../../../services/data_security";
import DeleteConfirmationDialog from "../../../utilities/delete-confirmation-dialog/DeleteConfirmationDialog";

const TransactionHistory = () => {
  const { setContextData } = useContextProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  var tablePageIndex = 1;
  var tablePageIndexRemit = 1;
  const [currentPage, setCurrentPage] = useState(tablePageIndex);
  const [currentPageRemit, setCurrentPageRemit] = useState(tablePageIndexRemit);
  const [itemsPerPage, setIemsPerPage] = useState(10);
  const [transactionList, setTransactionList] = useState<any[]>([]);
  const [transactionRemitList, setTransactionRemitList] = useState<any>();
  const [transactionListLoading, setTransactionListLoading] = useState(false);
  const [transactionRemitListLoading, setTransactionRemitListLoading] =
    useState(false);
  const [count, setCount] = useState(0);
  const [countRemit, setCountRemit] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [openCardAddCanvas, setOpenCardAddCanvas] = useState(false);
  const [cardAddLoading, setCardAddLoading] = useState(false);
  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [isEditDefaultCard, setIsEditDefaultCard] = useState(false);
  const [cardList, setCardList] = useState<any[]>([]);
  const [cardListLoading, setCardListLoading] = useState(false);
  const [cardLength, setCardLength] = useState(0);

  const [openCardEditCanvas, setOpenCardEditCanvas] = useState(false);
  const [cardEditLoading, setCardEditLoading] = useState(false);
  const [cardDeleteLoading, setCardDeleteLoading] = useState(false);
  const [cardAddApiCalling, setCardAddApiCalling] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [showCardListener, setShowCardListener] = useState(false);
  const [cardTokenizeOrderId, setCardTokenizeOrderId] = useState("");
  const [cardEditDetails, setCardEditDetails] = useState<any>();
  const [triggerDownload, setTriggerDownload] = useState<(() => void) | null>(
    null
  );
  const [isOnepayTransaction, setIsOnepayTransaction] = useState(true);
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const [isOpenRemitView,setOpenRemitView] = useState(false)
const [remitData,setRemitData] = useState<any>()
const [isDeleteCardConfirmDialog,setIsDeleteCardConfirmDialog] = useState(false)

  const handleDownloadPdf = async () => {
    // Wait a moment for the content to render
    setTimeout(async () => {
      if (pdfRef.current) {
        const canvas = await html2canvas(pdfRef.current);
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // Width of A4 in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("TransactionReceipt.pdf");
      }
    }, 100);
  };
  const openViewDetails = ()=>{

  }

  useEffect(() => {
    getTransactionList(tablePageIndex);

    getTokenizedCardDetails();
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "authenticate-payer-script";
    script.innerHTML = `
      var e = document.getElementById("threedsChallengeRedirectForm");
      if (e) {
        e.submit();
        if (e.parentNode !== null) {
          e.parentNode.removeChild(e);
        }
      }
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [htmlContent]);

  const tokenziedCallBack = (data: any) => {
    if (data === "SUCCESS") {
      setHtmlContent("");
      console.log("Tokenized callback called :", data);
      getTokenizedCardDetails();
      toast.success("Card tokenization successfully");
    } else if (data === "EMPTY_DOC") {
      console.log("Tokenized callback called :", data);
      //toast.success("Card tokenization failed(doc)");
    } else {
      setHtmlContent("");
      console.log("Tokenized callback called :", data);
      toast.error("Card tokenization failed");
    }
  };
  const getTokenizedCardDetails = async () => {
    setCardListLoading(true);
    const cardListRes = await getTokenizeCardList();
    if (cardListRes.code === 100) {
      setCardListLoading(false);
      setCardLength(cardListRes.data.count);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Use 'auto' for instant scrolling
      });
      setCardList(cardListRes.data.results);
      setContextData({ cardDetails: cardListRes.data.results });
    } else {
      setCardListLoading(false);
    }
  };

  const formikCardAdd = useFormik({
    initialValues: {
      nameOnCard: "",
      nickName: "",
      cardNumber: "",
      expireDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      nameOnCard: Yup.string().required("Required"),
      nickName: Yup.string(),
      cardNumber: Yup.number().required("Required"),
      expireDate: Yup.string().required("Required"),
      cvv: Yup.number().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setCardAddLoading(true);
      const cardData = {
        name_on_card: values.nameOnCard,
        card_number: encryptMessage(values.cardNumber),
        expire_month: encryptMessage(values.expireDate.split("/")[0]),
        expire_year: encryptMessage(values.expireDate.split("/")[1]),
        cvv: encryptMessage(values.cvv),
        card_nick_name: values.nickName,
        is_default: isDefaultCard,
      };
      try {
        console.log(cardData)
        const addRes = await cardTokenize(cardData);
        console.log(addRes.code);

        if (addRes.code === 100) {
          console.log(
            "Card add successfully",
            addRes.data.api_response.session_id.redirect_html
          );
          setShowIframe(true);
          setCardAddLoading(false);
          setOpenCardAddCanvas(false);
          setShowCardListener(true);
          resetForm();
          formikCardAdd.resetForm();
          setCardTokenizeOrderId(addRes.data.api_response.session_id.order_id);
          setHtmlContent(addRes.data.api_response.session_id.redirect_html);
        } else {
          toast.success("Failed to card add");
          console.error("Failed to card add");
          setCardAddLoading(false);
          setOpenCardAddCanvas(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  const handleToggle = (toggleName: any, isToggled: any) => {
    if (toggleName === "add") setIsDefaultCard(isToggled);
    if (toggleName === "edit") setIsEditDefaultCard(isToggled);
  };

  const formikCardEdit = useFormik({
    initialValues: {
      cardNumber: "",
      nickName: "",
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string(),
      nickName: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setCardEditLoading(true);
      const cardEditData = {
        id: cardEditDetails?.creditcard_id,
        card_nick_name: values.nickName,
        is_default: isEditDefaultCard,
      };
      try {
        const editCardRes = await editCardTokenize(cardEditData);
        console.log(editCardRes.code);

        if (editCardRes.code === 100) {
          toast.success("Success");
          console.log("Card edit success");
          setCardEditLoading(false);
          setOpenCardEditCanvas(false);
          getTokenizedCardDetails();
          resetForm();
          formikCardEdit.resetForm();
        } else {
          setCardEditLoading(false);
          setOpenCardEditCanvas(false);
          toast.success("Failed");
          console.error("Failed to card edit");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  const handlePageChangeOrder = async (pageNumber: any) => {
    setTransactionListLoading(true);
    await setCurrentPage(pageNumber);
    tablePageIndex = pageNumber;
    setTransactionList([]);
    getTransactionList(pageNumber);
  };
  const handlePageChangeOrderRemit = async (pageNumber: any) => {
    setTransactionRemitListLoading(true);
    await setCurrentPageRemit(pageNumber);
    tablePageIndexRemit = pageNumber;
    setTransactionRemitList({});
    getRemitTransactionsList(pageNumber);
  };
  const formatDate = (dateString: any) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  const getTransactionList = async (page: any) => {
    setTransactionListLoading(true);
    const offSetValue = (page - 1) * 10;
    const transactionListRes = await getTransactions(email, offSetValue);
    if (transactionListRes.code === 100) {
      setTransactionListLoading(false);
      setCount(transactionListRes.data.count);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Use 'auto' for instant scrolling
      });
      setTransactionList(transactionListRes.data.results);
    } else {
      setTransactionListLoading(false);
    }
  };
  const getRemitTransactionsList = async (page: any) => {
    setTransactionRemitListLoading(true);
    const transactionRemitListRes = await getRemitTransactions(page);
    if (transactionRemitListRes.code === 100) {
      setTransactionRemitListLoading(false);
      setCountRemit(transactionRemitListRes.data.count);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Use 'auto' for instant scrolling
      });
      setTransactionRemitList(transactionRemitListRes.data.results);
    } else {
      setTransactionRemitListLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copied");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  const deleteCardToken = async(id:any)=>{
    setCardDeleteLoading(true)
    const del_res = await deleteCardTokenize(id)
    if(del_res){
      //toast.success("Card delete happened");
      console.log("Card delete responce : ",del_res)
      setCardDeleteLoading(true);
    }else {
      setCardDeleteLoading(false);
    }
  }
  const closeDeleteCardConfrimDialog =()=>{
    setIsDeleteCardConfirmDialog(false)
  }
  const openDeleteCardConfrimDialog =()=>{
    setIsDeleteCardConfirmDialog(true)
  }

  return (
    <>
      {htmlContent && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%" }}
        >
          <div
            style={{ width: "100%" }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
          <CardTokenizationListener
            cardTokenId={cardTokenizeOrderId}
            cardTokenizeCallBack={tokenziedCallBack}
          />
        </div>
      )}
      {isOpenRemitView && <RemitTransactionView data={remitData} onClose={function (): void {
       setOpenRemitView(false)
      } }/>}
      {isDeleteCardConfirmDialog && (
        <DeleteConfirmationDialog
          deleteItemName="tokenized card"
          deleteFunction={() => {
            deleteCardToken(cardEditDetails?.creditcard_id);
          }}
          onClose={closeDeleteCardConfrimDialog}
          isButtonLoading={cardDeleteLoading}
        />
      )}
      {!htmlContent && (
        <>
          <div className="main-payment-div">
            <NavBar verifiedEmail={email} />
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <div className="topic">
                  View your all transactions with OneID
                </div>
                <div className="sub-topic">
                  Monitor your transaction in one place.
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <div className="promotion-btn-div">
                  {/* <a
                    href="https://whatsapp.com/channel/0029VaiBDez84OmIUfJGlT1y"
                    target="blank"
                    className="navbar-send-money-btn"
                  >
                    <span>
                      <img src={whatsapp_icon} alt="" width={20} />
                    </span>
                    Promotions{" "}
                    <span>
                      <img src={arrow_line_right} alt="" width={16} />
                    </span>
                  </a> */}
                  <div
                    className="navbar-send-money-btn"
                    onClick={() => {
                      navigate(`/send-money`);
                    }}
                  >
                    <span>
                      <img src={money_icon} alt="" width={20} />
                    </span>
                    <span>Send money to Sri Lanka </span>
                    <span>
                      <img src={arrow_line_right} alt="" width={16} />
                    </span>
                  </div>
                </div>
                <div className="add-btn-display">
                  <div className="add-button ">
                    <img src={add_icon} alt="" width={24} />
                    <div
                      className="add-btn-text ml-2"
                      onClick={() => {
                        setOpenCardAddCanvas(true);
                        // setShowIframe(true);
                      }}
                    >
                      Add card
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-btn-display2">
              <div className="add-button2 mb-3">
                <img src={add_icon} alt="" width={24} />
                <div
                  className="add-btn-text ml-2"
                  onClick={() => {
                    setOpenCardAddCanvas(true);
                    // setShowIframe(true);
                  }}
                >
                  Add card
                </div>
              </div>
            </div>

            <div className="promotion-btn-div2">
              {/* <a
                  href="https://whatsapp.com/channel/0029VaiBDez84OmIUfJGlT1y"
                  target="blank"
                  className="navbar-send-money-btn2"
                >
                  <span>
                    <img src={whatsapp_icon} alt="" width={20} />
                  </span>
                  Promotions{" "}
                  <span>
                    <img src={arrow_line_right} alt="" width={16} />
                  </span>
                </a> */}
              <div
                className="navbar-send-money-btn3"
                onClick={() => {
                  navigate(`/send-money`);
                }}
              >
                <span>
                  <img src={money_icon} alt="" width={20} />
                </span>
                <span>Send money to Sri Lanka </span>
                <span>
                  <img src={arrow_line_right} alt="" width={16} />
                </span>
              </div>
            </div>
            <div className="invite-div1">
              <div className="invite-friend-div">
                <div className="d-flex justify-content-start align-items-center">
                  <img src={invite_img} alt="" width={41} />
                  <div className="pl-2">
                    <div className="invite_text">Invite friends</div>
                    <div className="award_text">Earn $1 for every $100</div>
                  </div>
                </div>
                <img
                  src={invite_arrow}
                  alt=""
                  width={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    //navigate(`/invite-friend`);
                    toast.success("Coming soon");
                  }}
                />
              </div>
            </div>
            {!cardListLoading ? (
              <>
                {cardLength === 0 && (
                  <div className="no-card-option-div">
                    <div
                      className="mt-4 mb-3 card-grid"
                      style={{ opacity: 0.3 }}
                    >
                      <div className="card-div">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <img src={visa_icon} alt="" width={23} />
                            <div className="holder-name ml-2">ABCD John</div>
                            <div className="default-card ml-2">default</div>
                          </div>
                          <div style={{ cursor: "pointer" }}>
                            <img src={arrow_line_right2} alt="" width={20} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <div className="card-number-text">
                            xxxx xxxx xxxx 8906
                          </div>
                          <div className="expire-date">19/08</div>
                        </div>
                      </div>
                      <div className="card-div">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <img src={master_card_icon} alt="" width={20} />
                            <div className="holder-name ml-2">ABCD John</div>
                            <div className="default-card ml-2">default</div>
                          </div>
                          <div style={{ cursor: "pointer" }}>
                            <img src={arrow_line_right2} alt="" width={20} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <div className="card-number-text">
                            xxxx xxxx xxxx 8906
                          </div>
                          <div className="expire-date">19/08</div>
                        </div>
                      </div>
                      <div className="card-div">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            <img src={visa_icon} alt="" width={23} />
                            <div className="holder-name ml-2">ABCD John</div>
                            <div className="default-card ml-2">default</div>
                          </div>
                          <div style={{ cursor: "pointer" }}>
                            <img src={arrow_line_right2} alt="" width={20} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <div className="card-number-text">
                            xxxx xxxx xxxx 8906
                          </div>
                          <div className="expire-date">19/08</div>
                        </div>
                      </div>
                    </div>
                    <div className="no-card-option">
                      <div className="no-card-option-text">
                        No cards added yet. add your cards
                      </div>
                      <div className="no-card-option-text">
                        to manage your payments
                      </div>
                      <div className="no-card-for-add-btn">
                        <div className="add-button mb-3 mt-2">
                          <img src={add_icon} alt="" width={24} />
                          <div
                            className="add-btn-text ml-2"
                            onClick={() => {
                              setOpenCardAddCanvas(true);
                            }}
                          >
                            Add card
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {cardLength !== 0 && (
                  <div className="mt-4 mb-3 card-grid">
                    {cardList.map((data, index) => (
                      <div key={index} className="card-div">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-start align-items-center">
                            {data?.card_brand === "MASTERCARD" ? (
                              <img src={master_card_icon} alt="" width={20} />
                            ) : (
                              <img src={visa_icon} alt="" width={23} />
                            )}
                            <div className="holder-name ml-2">
                              {data?.card_nick_name}
                            </div>
                            {data?.is_default ? (
                              <div className="default-card ml-2">default</div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setCardEditDetails(data);
                              formikCardEdit.values.cardNumber =
                                "xxxx xxxx xxxx " + data?.card_number.slice(-4);
                              formikCardEdit.values.nickName =
                                data?.card_nick_name;
                              setIsEditDefaultCard(data?.is_default);
                              setOpenCardEditCanvas(true);
                            }}
                          >
                            <img src={arrow_line_right2} alt="" width={20} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <div className="card-number-text">
                            xxxx xxxx xxxx {data?.card_number?.slice(-4)}
                          </div>
                          <div className="expire-date">
                            {data?.expire_month}/{data?.expire_year}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className="loader vh-30 vw-100">
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
              </div>
            )}
            <div className="invite-div2">
              <div className="invite-friend-div">
                <div className="d-flex justify-content-start align-items-center">
                  <img src={invite_img} alt="" width={41} />
                  <div className="pl-2">
                    <div className="invite_text">Invite friends</div>
                    <div className="award_text">Earn $1 for every $100</div>
                  </div>
                </div>
                <img
                  src={invite_arrow}
                  alt=""
                  width={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    //navigate(`/invite-friend`);
                    toast.success("Coming soon");
                  }}
                />
              </div>
            </div>

            {/*  */}
            <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
              <div className="transaction-text">Transactions</div>
              {/* <img
                src={filter_icon}
                alt=""
                style={{ position: "relative", top: 0, right: 0 }}
                width={24}
              /> */}
            </div>
            <div className="d-flex justify-content-start align-items-center mb-3 mt-2">
              <div
                className={`${
                  isOnepayTransaction
                    ? "select-transaction-div"
                    : "unselect-transaction-div"
                }`}
                onClick={() => {
                  setIsOnepayTransaction(true);
                  getTransactionList(tablePageIndex);
                }}
              >
                Onepay Transaction
              </div>
              <div
                className={`${
                  !isOnepayTransaction
                    ? "select-transaction-div"
                    : "unselect-transaction-div"
                }`}
                onClick={() => {
                  setIsOnepayTransaction(false);
                  getRemitTransactionsList(currentPageRemit);
                }}
              >
                OneRemit Transaction
              </div>
            </div>
            {isOnepayTransaction ? (
              <div className="list-div">
                {!transactionListLoading ? (
                  <>
                    {transactionList.length !== 0 ? (
                      <>
                        {transactionList.map((data: any, index: any) => (
                          <>
                            <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                              <div className="date-text">
                                {formatDate(data.request_datetime)}
                              </div>
                            </div>
                            <div className="card">
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <img
                                  src={`${aws_s3}${data?.merchant_logo}`}
                                  alt=""
                                  width={60}
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "-9999px",
                                    top: "-9999px",
                                  }}
                                >
                                  <TransactionReceiptPdf
                                    {...data}
                                    ref={pdfRef}
                                  />
                                </div>
                                <div
                                  className="download-recipt-text"
                                  onClick={handleDownloadPdf}
                                >
                                  Download receipt{" "}
                                </div>
                              </div>
                              <>
                                <div className="details-div">
                                  <div className="details-sub-div">
                                    <div className="details-topic-text">
                                      Merchant name
                                    </div>
                                    <div className="details-sub-topic-text">
                                      {data?.business_name}
                                    </div>
                                  </div>
                                  <div className="details-sub-div">
                                    <div className="details-topic-text">
                                      Transaction date
                                    </div>
                                    <div className="details-sub-topic-text">
                                      {formatDate(data?.request_datetime)}
                                    </div>
                                  </div>
                                  <div className="details-sub-div">
                                    <div className="details-topic-text">
                                      Amount
                                    </div>
                                    <div className="details-sub-topic-text">
                                      {data?.request_amount} {data.currency}
                                    </div>
                                  </div>
                                  <div className="details-sub-div">
                                    <div className="details-topic-text">
                                      Payment method
                                    </div>
                                    <div className="details-sub-topic-text">
                                      {data?.transaction_type || "--"}
                                    </div>
                                  </div>
                                  <div className="details-sub-div">
                                    <div className="details-topic-text">
                                      Transaction id
                                    </div>
                                    <div className="details-sub-topic-text">
                                      {data?.onepay_transaction_id || "--"}{" "}
                                      <span>
                                        <img
                                          src={copy_icon}
                                          alt=""
                                          width={12}
                                          style={{
                                            marginLeft: "3px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            copyToClipboard(
                                              data?.onepay_transaction_id
                                            );
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <div className="no-data-text vh-50 vw-100 d-flex justify-content-center align-items-center">
                        No transaction available.
                      </div>
                    )}
                  </>
                ) : (
                  <div className="loader vh-100 vw-100">
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                  </div>
                )}
              </div>
            ) : (
              <div className="list-div">
                {!transactionRemitListLoading ? (
                  <>
                    {transactionRemitList.length !== 0 ? (
                      <>
                        {Object.entries(transactionRemitList).map(
                          ([date, transactions]: [any, any]) => (
                            <>
                              <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
                                <div className="date-text">
                                  {formatDate(date)}
                                </div>
                              </div>
                              {transactions.map((data: any) => (
                                <div className="card mt-2 mb-2" style={{cursor:"pointer"}}>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <img
                                      src={`${aws_s3}${data?.merchant_logo}`}
                                      alt=""
                                      width={60}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: "-9999px",
                                        top: "-9999px",
                                      }}
                                    ></div>
                                    <div
                                      className="download-recipt-text"
                                      onClick={()=>{
                                        setOpenRemitView(true)
                                        setRemitData(data)
                                      }}
                                    >
                                      View
                                    </div>
                                  </div>
                                  <>
                                    <div className="details-div2">
                                      <div className="details-sub-div">
                                        <div className="details-topic-text">
                                          Service name
                                        </div>
                                        <div className="details-sub-topic-text">
                                          OneRemit
                                        </div>
                                      </div>
                                      <div className="details-sub-div">
                                        <div className="details-topic-text">
                                          Transaction date
                                        </div>
                                        <div className="details-sub-topic-text">
                                          {formatDate(data?.created_at_timestamp)}
                                        </div>
                                      </div>
                                      <div className="details-sub-div">
                                        <div className="details-topic-text">
                                          Amount and currency
                                        </div>
                                        <div className="details-sub-topic-text">
                                          {data?.from_amount} {data?.from_currency_code}
                                        </div>
                                      </div>
                                      <div className="details-sub-div">
                                        <div className="details-topic-text">
                                          Payment method
                                        </div>
                                        <div className="details-sub-topic-text">
                                          {data?.card_nick_name || "--"}
                                        </div>
                                      </div>
                                      <div className="details-sub-div">
                                        <div className="details-topic-text">
                                          Transaction id
                                        </div>
                                        <div className="details-sub-topic-text">
                                          {data?.transaction_id || "--"}{" "}
                                          <span>
                                            <img
                                              src={copy_icon}
                                              alt=""
                                              width={12}
                                              style={{
                                                marginLeft: "3px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                copyToClipboard(
                                                  data?.onepay_transaction_id
                                                );
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="details-sub-div">
                                        {data?.mpgs_transaction_status ==="SUCCESS"?(<div className="succes-trans">Success</div>):(<div className="failed-trans">failed</div>)}
                                        
                                        
                                      </div>
                                    </div>
                                  </>
                                </div>
                              ))}
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <div className="no-data-text vh-50 vw-100 d-flex justify-content-center align-items-center">
                        No transaction available.
                      </div>
                    )}
                  </>
                ) : (
                  <div className="loader vh-100 vw-100">
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                  </div>
                )}
              </div>
            )}

            {count !== 0 && isOnepayTransaction && (
              <div
                style={{ width: "100%", paddingBottom: "20px" }}
                className="mb-3"
              >
                {" "}
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={count}
                  currentPage={currentPage}
                  onPageChange={handlePageChangeOrder}
                />
              </div>
            )}
            {countRemit !== 0 && !isOnepayTransaction && (
              <div
                style={{ width: "100%", paddingBottom: "20px" }}
                className="mb-3"
              >
                {" "}
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={countRemit}
                  currentPage={currentPageRemit}
                  onPageChange={handlePageChangeOrderRemit}
                />
              </div>
            )}
            {openCardAddCanvas && (
              <LeftOffCanvas
                onClose={() => {
                  setOpenCardAddCanvas(false);
                  formikCardAdd.resetForm();
                }}
                heading={"Add new card"}
              >
                <div className="canvas-sub-topic-text">
                  Set PIN code for your account extra security.
                </div>
                <form onSubmit={formikCardAdd.handleSubmit}>
                  <div>
                    <div className="offcanvas-label-div-text">
                      Name on the card
                    </div>
                    <div className="div-type-input2">
                      <input
                        type="text"
                        className="bot-name-div-type-input"
                        placeholder="Name on the card"
                        {...formikCardAdd.getFieldProps("nameOnCard")}
                      />
                    </div>
                    {formikCardAdd.touched.nameOnCard &&
                    formikCardAdd.errors.nameOnCard ? (
                      <div className="formik-errors">
                        {formikCardAdd.errors.nameOnCard}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div className="offcanvas-label-div-text">
                      Card nike name
                    </div>
                    <div className="div-type-input2">
                      <input
                        type="text"
                        className="bot-name-div-type-input"
                        placeholder="Card nike name"
                        {...formikCardAdd.getFieldProps("nickName")}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="offcanvas-label-div-text">Card number</div>
                    <div className="div-type-input2">
                      {/* <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Card number"
                  {...formikCardAdd.getFieldProps("cardNumber")}
                /> */}
                      <input
                        type="text"
                        className="bot-name-div-type-input"
                        placeholder="Card number"
                        maxLength={19} // Max length includes 3 spaces for formatting
                        value={formikCardAdd.values.cardNumber
                          .replace(/\W/gi, "")
                          .replace(/(.{4})/g, "$1 ")
                          .trim()}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                          formikCardAdd.setFieldValue("cardNumber", value);
                        }}
                        onBlur={formikCardAdd.handleBlur}
                        inputMode="numeric"
                        // pattern="[0-9]*"
                      />
                    </div>
                    {formikCardAdd.touched.cardNumber &&
                    formikCardAdd.errors.cardNumber ? (
                      <div className="formik-errors">
                        {formikCardAdd.errors.cardNumber}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div className="offcanvas-label-div-text">Expire date</div>
                    <div className="div-type-input2">
                      {/* <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Expire date"
                  {...formikCardAdd.getFieldProps("expireDate")}
                /> */}
                      <input
                        type="text"
                        className="bot-name-div-type-input"
                        placeholder="MM/YY"
                        maxLength={5}
                        value={formikCardAdd.values.expireDate}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                          if (value.length > 2) {
                            value =
                              value.substring(0, 2) +
                              "/" +
                              value.substring(2, 4);
                          }
                          formikCardAdd.setFieldValue("expireDate", value);
                        }}
                        onBlur={formikCardAdd.handleBlur}
                        inputMode="numeric"
                        // pattern="[0-9]*"
                      />
                    </div>
                    {formikCardAdd.touched.expireDate &&
                    formikCardAdd.errors.expireDate ? (
                      <div className="formik-errors">
                        {formikCardAdd.errors.expireDate}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div className="offcanvas-label-div-text">CVV</div>
                    <div className="div-type-input2">
                      <input
                        type="text"
                        maxLength={3}
                        className="bot-name-div-type-input"
                        placeholder="cvv"
                        {...formikCardAdd.getFieldProps("cvv")}
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                    </div>
                    {formikCardAdd.touched.cvv && formikCardAdd.errors.cvv ? (
                      <div className="formik-errors">
                        {formikCardAdd.errors.cvv}
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
                    <ToggleButton
                      onToggle={(isToggled) => handleToggle("add", isToggled)}
                      initialState={isDefaultCard}
                    />
                    <div className="toggle-text ml-3">
                      Enable as a default card
                    </div>
                  </div>
                  <div className="sercure-by-div">
                    <img src={secure_by} alt="" width={21} />
                    <div>
                      <div className="sercure-by-div-text">
                        Secured by Spemai
                      </div>
                      {/* <div className="sercure-by-div-text">
                    Spemai</div> */}
                    </div>
                  </div>
                  <Button
                    label="Save"
                    type="submit"
                    className="address-save-btn"
                    onClick={() => {}}
                    loading={cardAddLoading}
                  />
                </form>
              </LeftOffCanvas>
            )}
            {openCardEditCanvas && (
              <LeftOffCanvas
                onClose={() => {
                  setOpenCardEditCanvas(false);
                  formikCardEdit.resetForm();
                }}
                heading={"Edit card"}
              >
                <div className="canvas-sub-topic-text">
                  Set PIN code for your account extra security.
                </div>
                <form onSubmit={formikCardEdit.handleSubmit}>
                  <div>
                    <div className="offcanvas-label-div-text">
                      Card nike name
                    </div>
                    <div className="div-type-input2">
                      <input
                        type="text"
                        className="bot-name-div-type-input"
                        placeholder="Card nike name"
                        {...formikCardEdit.getFieldProps("nickName")}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="offcanvas-label-div-text">Card number</div>
                    <div className="div-type-input2 disabled-area">
                      <input
                        type="text"
                        disabled
                        className="bot-name-div-type-input"
                        placeholder="Card number"
                        {...formikCardEdit.getFieldProps("cardNumber")}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
                    <ToggleButton
                      onToggle={(isToggled) => handleToggle("edit", isToggled)}
                      initialState={isEditDefaultCard}
                    />
                    <div className="toggle-text ml-3">
                      Enable as a default card
                    </div>
                  </div>
                  <div className="sercure-by-div">
                    <img src={secure_by} alt="" width={21} />
                    <div>
                      <div className="sercure-by-div-text">
                        Secured by Spemai
                      </div>
                      {/* <div className="sercure-by-div-text">
                    Spemai</div> */}
                    </div>
                  </div>
                  <Button
                    label="Save"
                    type="submit"
                    className="address-save-btn"
                    onClick={() => {}}
                    loading={cardEditLoading}
                  />
                  <Button
                    label="Delete card"
                    type="button"
                    className="card-delete-btn"
                    onClick={openDeleteCardConfrimDialog}
                    loading={false}
                  />
                </form>
              </LeftOffCanvas>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TransactionHistory;
