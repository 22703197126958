import React, { useState } from "react";
import "./NavBar.css";
import logo from "../../assets/Logo3.jpg";
import logout_icon from "../../assets/logout-circle-line.png";
import profile_icon from "../../assets/user-line.png";
import profile from "../../assets/profile-img.png";
import arrow_line_right from "../../assets/arrow-right-line.png"
import { useLocation, useNavigate } from "react-router-dom";
import whatsapp_icon from "../../assets/whatsapp-icon.png"
import toast from "react-hot-toast";
interface NavbarProps {
  verifiedEmail:any
}
const NavBar: React.FC<NavbarProps> = ({verifiedEmail}) => {
  const location = useLocation();
  const renderPromotionBtn = () => {
    const pathsWithFooter = ['/profile'];
    return pathsWithFooter.includes(location.pathname); 
  };
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const handleIconClick = () => {
    setShowTooltip(!showTooltip);
    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <img src={logo} alt="" width={50} style={{cursor:"pointer"}} onClick={()=>{
        navigate(`/transaction-history`, { state: { email: verifiedEmail } });
      }}/>
      <div className="profile-container d-flex justify-content-end align-items-center">
        {/* {!renderPromotionBtn() && <div className="promotion-btn-div"><a href="https://whatsapp.com/channel/0029VaiBDez84OmIUfJGlT1y" target="blank" className="navbar-send-money-btn"><span><img src={whatsapp_icon} alt="" width={20} /></span>Promotions <span><img src={arrow_line_right} alt="" width={16} /></span></a></div>} */}
        
        <img
          src={profile}
          alt=""
          width={36}
          className="profile-icon"
          onClick={handleIconClick}
        />
        <div className={`tooltip ${showTooltip ? "show" : ""}`}>
          <div
            className="tooltip-text-div"
            onClick={() => {
              setShowTooltip(false);
              //toast.error("Coming soon");
              navigate(`/profile`, { state: { email: verifiedEmail } });
            }}
          >
            <span>
              <img src={profile_icon} alt="" width={16} />
            </span>{" "}
            Profile
          </div>
          <div
            className="tooltip-text-div"
            onClick={() => {
              setShowTooltip(false);
              localStorage.setItem("token", "");
              navigate(`/login`);
            }}
          >
            <span>
              <img src={logout_icon} alt="" width={16} />
            </span>{" "}
            Sign out
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
