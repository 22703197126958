import React, { useState } from "react";
import NavBar from "../../../utilities/navbar/NavBar";
import copy from "../../../assets/copy.png";
import money from "../../../assets/image 19.png";
import arrow_green from "../../../assets/arrow-right-line (3).png";
import "./InviteFriends.css";
import PointTransfer from "../point-transfer/PointTransfer";
import arrow_left from "../../../assets/Frame 5524.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const InviteFriends = () => {
  const navigate = useNavigate();
  const [sendPointsView, setSendPointsView] = useState(false);
  const sendPointCallbck = () => {
    setSendPointsView(false);
  };
  const copyToClipboard = () => {
    const textToCopy = document.querySelector(".link-text")?.textContent;
    if (textToCopy) {
      // Ensure textToCopy is not null
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Copied");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      console.error("No text found to copy.");
    }
  };

  return (
    <div className="main-invite-div2">
      <NavBar verifiedEmail={localStorage.getItem("userEmail")} />
      <div className="d-flex justify-content-start align-items-center mt-4">
        <img
          src={arrow_left}
          alt=""
          width={24}
          style={{ cursor: "pointer", marginRight: "20px" }}
          onClick={() => {
            navigate(`/transaction-history`, {
              state: { email: localStorage.getItem("userEmail") },
            });
          }}
        />
        <div className="">
          <div className="invite-friend-text">Invite friends</div>
          <div className="invite-friend-text-sub">
            Invite friends and get rewards
          </div>
        </div>
      </div>
      {sendPointsView ? (
        <PointTransfer onClose={sendPointCallbck} />
      ) : (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="copy-url-text mt-5 mb-2">Copy URL</div>
            <div className="d-flex justify-content-between align-items-center invite-link-div mt-2 mb-3">
              <div className="link-text">https://nksndkndksndk/djsdb</div>
              <img
                src={copy}
                alt=""
                width={24}
                style={{ cursor: "pointer" }}
                onClick={copyToClipboard}
              />
            </div>
            <div className="earn-desc">Earn $1 for every $100 transaction</div>
            <div className="div-border"></div>
            <div className="your-earing-text">Your earnings</div>
            <div className="d-flex justify-content-between align-items-center points-div mt-3 mb-2">
              <div>
                <div className="points-value">00.00</div>
                <div className="points-text">points</div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <img src={money} alt="" width={28} />{" "}
                <div className="transfer-text">Transfer</div>{" "}
                <img
                  src={arrow_green}
                  alt=""
                  width={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSendPointsView(true);
                  }}
                />
              </div>
            </div>
            <div className="warning-text">
              *Minimum transferable points 3000
            </div>
            <div className="div-border mt-4 mb-3"></div>
            <div className="d-flex justify-content-between align-items-start points-div mt-3 mb-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="points-text">Total users</div>
                <div className="points-value mt-2">0</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="points-text">Transfer amount</div>
                <div className="points-value mt-2">00.00</div>
                <div className="points-text mt-1">USD</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InviteFriends;
