import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import group1 from "../../assets/Group1.png";
import "./RemitTransactionView.css";

interface TransactionDetailsProps {
  data: any;
  onClose: () => void;
}
const RemitTransactionView: React.FC<TransactionDetailsProps> = ({
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const [isOpenView, setIsOpenView] = useState(true);
  const handleClose = () => {
    setIsOpenView(false);
    onClose()
  };
  return (
    <>
      <div className={`confirmation-dialog-app5 ${isOpenView ? "show" : ""}`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="view-details">View event details</div>
          <img className="pe-2 close-icon" src={group1} onClick={handleClose} />
        </div>
        <div className="d-flex justify-content-start align-items-center details-topic mb-3 mt-2">
          Payment information
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Send amount</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.from_amount} USD</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Card fees</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.card_fees} USD</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Instant transfer fee</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.instant_transfer_fee} USD</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Total fees</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {(Number(data?.card_fees)+Number(data?.instant_transfer_fee)).toFixed(2)} USD</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Exchange rate</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.exchange_rate} USD</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Payment status</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.mpgs_transaction_status} </div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Payment method</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.card_brand}</div>
        </div>
        <div className="d-flex justify-content-start align-items-center details-topic mb-3 mt-2">
          Beneficiary details
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Recipient account name</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.recipient_name}</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Recipient account number</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.recipient_account_number}</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Recipient mobile number</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.recipient_mobile_number}</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Recipient bank</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.recipient_bank_name}</div>
        </div>
        <div className="d-flex justify-content-start align-items-start vw-100">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Transfer description</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: {data?.transaction_narration}</div>
        </div>
        {/* <div className="d-flex justify-content-start align-items-center vw-100 mb-3 mt-3">
          <div className="d-flex justify-content-start align-items-center vw-40 details-sub-topic1">Note</div>
          <div className="d-flex justify-content-start align-items-center vw-60 details-sub-topic2">: John</div>
        </div> */}
      </div>

      {isOpenView && <div className="confirmation-overlay"></div>}
    </>
  );
};

export default RemitTransactionView;
