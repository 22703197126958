// PDFContentComponent.js
import React, { ForwardedRef } from "react";
import "./TransactionReceiptPdf.css";

const formatDate = (dateString: any) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};
export const TransactionReceiptPdf = React.forwardRef<HTMLDivElement>(
  (props: any, ref: ForwardedRef<HTMLDivElement>) => (
    <div ref={ref} className="">
      <div className="pdf-container">
        <div className="div-f">
          <div className="div-1">
            <img src="/assets/image 24.png" alt="" width="51" />
            <div className="text-div">
              <div className="congratz-text">Congratulations..!!</div>
              <div className="succes-text">
                <span className="succes-ampount">Lkr 6700.00</span> Paid
                successfully..
              </div>
            </div>
          </div>
          <div className="paid-img">
            <img src="/assets/image 25.png" alt="" width="71" height="57" />
          </div>
        </div>
        <div className="div-s">
          <div className="vw-100 div-s-1">
            <div>
              <div className="payment-receipt-text">
                <div>Payment receipt</div>
              </div>
              <div className="sub-details">
                Transaction id: {props?.onepay_transaction_id}
              </div>
              <div className="sub-details">
                {formatDate(props.request_datetime)}
              </div>
            </div>
            <div className="paid-img"><img src="/assets/logo3.jpg" alt="" width="51" /></div>
          </div>
          <div className="line-break"></div>
          <div className="sub-topic vw-100">Merchant details</div>
          <div className="sub-topic-details-div">
            <div className="sub-topic-details-text">Merchant name</div>
            <div className="sub-topic-details-text">{props?.business_name}</div>
          </div>
          <div className="sub-topic-details-div">
            <div className="sub-topic-details-text">Brand name</div>
            <div className="sub-topic-details-text">ada, a,d</div>
          </div>
          <div className="sub-topic-details-div">
            <div className="sub-topic-details-text">Merchant email</div>
            <div className="sub-topic-details-text">ada, a,d</div>
          </div>
          <div className="line-break"></div>
          <div className="sub-topic vw-100">Customer details</div>
          <div className="sub-topic-details-div">
            <div className="sub-topic-details-text">Customer name</div>
            <div className="sub-topic-details-text">ada, a,d</div>
          </div>
          <div className="sub-topic-details-div">
            <div className="sub-topic-details-text">Customer number</div>
            <div className="sub-topic-details-text">ada, a,d</div>
          </div>
          <div className="sub-topic-details-div">
            <div className="sub-topic-details-text">Amount</div>
            <div className="sub-topic-details-text">ada, a,d</div>
          </div>
          <div className="line-break mt-60"></div>
          <div className="sub-topic-details-div mb-50">
            <div className="total-paid-text">Total paid:</div>
            <div className="total-paid-text">
              {props?.net_amount} {props?.currency}
            </div>
          </div>
        </div>
        <div className="div-t">
          <div className="div-t-1 vw-100">
            <div className="hotline-text">
              Hotline: +94(11) 7 021 540 Web: www.onepay.lk Email:
              info@onepay.lk
            </div>
            <div className="poweredby-text">
              Powered by Onepay info@onepay.lk
            </div>
          </div>
        </div>
      </div>
    </div>
  )
);
