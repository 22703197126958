import React from "react";
import NavBar from "../../../utilities/navbar/NavBar";
import arrow_left from "../../../assets/Frame 5524.png";
import "./PointTransfer.css";
import Button from "../../../utilities/button/Button";


const PointTransfer = ({ onClose }:{onClose: () => void}) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <div className="d-flex justify-content-start align-items-center div-width mb-4">
        <img
          src={arrow_left}
          alt=""
          width={24}
          style={{ cursor: "pointer", marginRight: "20px" }}
          onClick={()=>{
            onClose()
          }}
        />
        <div>
          <div className="point-topic">One ID Reward point</div>
          <div className="point-sub-topic">Invite friends and get rewards</div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="point-amount-text mb-3">Enter point amount</div>
          <input
                placeholder="00.00"
                className="points-value"
                type="number"
                style={{
                  border: "none",
                  width: "auto",
                  outline: "none",
                  textAlign:"center"
                }}
                // value={moneyTrnsferData?.amount}
                // onChange={handleSendAmountHandle}
                inputMode="decimal"
                pattern="[0-9]*[.,]?[0-9]*"
              />
          {/* <div className="points-value">00.00</div> */}
          <div className="points-text">points</div>
        </div>
      </div>
      <div className="div-border mt-4 mb-3"></div>
      <div className="div-width">
        <div className="d-flex justify-content-start align-items-center vw-100">
            <div className="vw-70 curency-rate">$1</div>
            <div className="curency-rate">= 300 points</div>
        </div>
        <div className="d-flex justify-content-start align-items-center vw-100">
            <div className="vw-70 curency-rate">LKR 1</div>
            <div className="curency-rate">= 1 point</div>
        </div>
      </div>
      <div className="div-border mt-4 mb-3"></div>
      <div className="div-width">
        <div className="d-flex justify-content-start align-items-center vw-100">
            <div className="vw-70 point-sub-topic">Remaining balance</div>
            <div className="curency-rate">= 0 points</div>
        </div>
      </div>
      <div className="div-width mt-2">
      <Button
                label="Transfer"
                type="submit"
                onClick={() => {}}
                className="transfer-button"
                loading={false}
              />
      </div>
    </div>
  );
};

export default PointTransfer;
