import axios from "axios";
import { base_url } from "../environment/environment";

const API_URL = base_url;

class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success:any, message:string, data:any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
  
}

export const verifyEmailExist = async (email: string): Promise<ApiResponse> => {
  try {
    const response = await axios.get(`${API_URL}check-email/${email}/`);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
};

export const oneIdLogin = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(API_URL + "login/", data);
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    console.log(error.response.data);
    const errorMessage = error.response?.data.non_field_errors[0] || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
};
export const oneIdRefreshToken = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(API_URL + "token/refresh/", data);
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    console.log(error.response.data);
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
};

export const oneIdRegister = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(API_URL + "register/", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error:any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
};
export const oneIdOTPSend = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(API_URL + "otp/", data);
    //return response.data;
    return new ApiResponse(100, response.data.message, response.data)
  } catch (error:any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    //return error;
    console.log(error)
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
  
};
export const oneIdForgotPWOTPSend = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(API_URL + "password/forgot/", data);
    //return response.data;
    return new ApiResponse(100, response.data.message, response.data)
  } catch (error:any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    //return error;
    console.log(error)
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
  
};

export const oneIdOTPVerification = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(API_URL + "otp/verify/", data);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error:any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
};
export const oneIdResetForgotPw = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(API_URL + "password/reset/", data);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error:any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    const errorMessage = error.response?.data?.message || 'An unknown error occurred';
    return new ApiResponse(105, errorMessage);
  }
};