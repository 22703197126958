import axios from "axios";
import { base_url } from "../environment/environment";

const API_URL = base_url;
class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success: any, message: string, data: any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
}

export const getTransactions = async (email: string,offset:any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }

  try {
    const response = await axios.get(
      `${API_URL}customer/transactions/${email}/?limit=10&offset=${offset}&ordering=-request_datetime`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    console.error("Error fetching transactions:", error);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getRemitTransactions = async (offset:any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }

  try {
    const response = await axios.get(
      `${API_URL}remit-transactions-history/?page=${offset}&page_size=10`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if(error.response?.status === 401){
      window.location.href = '/login'
    }
    console.error("Error fetching transactions:", error);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
