import React, { useEffect, useRef, useState } from "react";
import "./VerifyOTP.css";
import Button from "../../../utilities/button/Button";
import OTPInput from "../../../utilities/otp-input/OTPInput";
import logo from "../../../assets/Logo3.jpg";
import arrow_left from "../../../assets/arrow-left-line.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  oneIdOTPSend,
  oneIdOTPVerification,
  oneIdRefreshToken,
} from "../../../services/auth_api";
import toast from "react-hot-toast";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, isRegistered ,route_fp} = location.state || {};
  const [otp, setOTP] = useState("");
  const [otpVerifyLoading, setOtpVerifyLoading] = useState(false);
  const [sendAgsinOtpLoading, setAgainOtpSendLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60); // 60 seconds countdown
  const [isActive, setIsActive] = useState(true);
  const [verifiedEmail,setVerifiedEmail] = useState("")
  const otpInputRef = useRef<{ reset: () => void }>(null);
  const resetOTP = () => {
    otpInputRef.current?.reset();
  };

  useEffect(() => {
    let intervalId: any;
    if (timeLeft === 0) {
      setIsActive(false);
    }
    if (isActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, timeLeft]);

  const startTimer = () => {
    setIsActive(true);
  };

  const handleOTPChange = async(otp: string) => {
    setOTP(otp);
    if(otp.length === 6){
      if(otp ===""){
        toast.error("OTP can't be empty");
        return
      }
      setIsActive(false);
      setOtpVerifyLoading(true);
      
      const otpVerifyPayload = {
        email: email,
        otp_code: otp,
      };
      const otpVerifyRes = await oneIdOTPVerification(otpVerifyPayload);
      if (otpVerifyRes.code === 100) {
        console.log("Verify OTP : ", otpVerifyRes);
        setOtpVerifyLoading(false);
        if (otpVerifyRes.data.otp_verification.is_verified) {
          toast.success(otpVerifyRes.message);
          setVerifiedEmail(otpVerifyRes.data.otp_verification.email)
          localStorage.setItem("userEmail", email);
          localStorage.setItem("token", otpVerifyRes.data.access);
          if (isRegistered && !route_fp) {
            
            navigate(`/transaction-history`, { state: { email: otpVerifyRes.data.otp_verification.email } });
            setTimeout(async () => {
              const payLoad = {
                refresh: otpVerifyRes.data.refresh,
              };
              const refreshTokenRes = await oneIdRefreshToken(payLoad);
              if (refreshTokenRes.code === 100) {
                localStorage.setItem("token", refreshTokenRes.data.access);
              }
            }, 3540000);
          }else if(isRegistered && route_fp){
            setTimeout(async () => {
              const payLoad = {
                refresh: otpVerifyRes.data.refresh,
              };
              const refreshTokenRes = await oneIdRefreshToken(payLoad);
              if (refreshTokenRes.code === 100) {
                localStorage.setItem("token", refreshTokenRes.data.access);
              }
            }, 3540000);
            navigate(`/reset-password`, { state: { email: otpVerifyRes.data.otp_verification.email,otp:otpVerifyRes.data.otp_verification.otp_code } });
          } else {
            setTimeout(async () => {
              const payLoad = {
                refresh: otpVerifyRes.data.refresh,
              };
              const refreshTokenRes = await oneIdRefreshToken(payLoad);
              if (refreshTokenRes.code === 100) {
                localStorage.setItem("token", refreshTokenRes.data.access);
              }
            }, 3540000);
            navigate(`/set-password`, { state: { email: otpVerifyRes.data.otp_verification.email } });
          }
        }
      } else {
        console.log("Verify OTP : ", otpVerifyRes);
        toast.error(otpVerifyRes.message);
        setOtpVerifyLoading(false);
        setOTP("");
        setIsActive(true);
      }
    }
  };
  

  const sendAgainOtp = async () => {
    setAgainOtpSendLoading(true);
    resetOTP()
    setTimeLeft(60);
    setIsActive(false);
    const otpSendPayload = {
      email: email,
    };
    const otpSendRes = await oneIdOTPSend(otpSendPayload);
    if (otpSendRes.code === 100) {
      setIsActive(true);
      console.log("OTP send res: ", otpSendRes);
      toast.success(otpSendRes.message);
      setAgainOtpSendLoading(false);
      
    } else {
      setAgainOtpSendLoading(false);
      toast.error("OTP sending failed");
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="main-div">
        <div className="d-flex justify-content-start align-items-center mb-4 vw-100 arrow-left">
          <img
            src={arrow_left}
            alt=""
            width={24}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <img src={logo} alt="" width={140} />
        </div>
        <div className="d-flex justify-content-center align-items-center verify-otp-text">
          Verify your OTP
        </div>
        <div className="description-text">
        Simple, secure one‑click payments.
        </div>
        <div className="verify-otp-div">
          <div className="verify-otp-div-text">Verify your OPT</div>
          <div className="otp-expired-time-text">{timeLeft}s</div>
        </div>
        <div className={`otp-show-div ${!isActive ? 'disabled' : ''}`}>
        <OTPInput ref={otpInputRef} length={6} onChange={handleOTPChange} />
        </div>

        {/* <div style={{width:"100%"}} className={` ${!isActive ? 'disabled' : ''}`}>
          <Button
            label="Verify"
            type="submit"
            onClick={verifyOTP}
            className="sign-in-button"
            loading={otpVerifyLoading}
          />
        </div> */}
        {otpVerifyLoading && <div className="loader">
            <div className="bubble"></div>
            <div className="bubble"></div>
            <div className="bubble"></div>
          </div>}
        <div className="otp-email-des-text otp-show-div mt-4">
          Your OTP send to<span className="otp-email-text">{email}</span>
        </div>
        {sendAgsinOtpLoading ? (<div className="loader">
            <div className="bubble"></div>
            <div className="bubble"></div>
            <div className="bubble"></div>
          </div>):(<div
          className="otp-send-text"
          aria-disabled={sendAgsinOtpLoading}
          onClick={sendAgainOtp}
        >
          Send again
        </div>)}
        
      </div>
    </div>
  );
};

export default VerifyOTP;
